<template>
  <div class="flex flex-col gap-2">
    <NavBar/>
    <div class="relative flex flex-col items-center gap-6 w-full">
      <div v-if="mappedSongs.length > 0" class="flex flex-col gap-10 p-4 border-4 border-tp-yellow rounded-xl">
        <div class="flex flex-col items-center gap-4">
          <img :src="trophy1" alt="" class="w-10">
          <div class="relative">
            <img :src="vinyl" loading="lazy" class="w-56 border-2 border-tp-yellow rounded-full" alt="">
            <img :src="mappedSongs[0].image" alt="" class="absolute  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 rounded-full object-cover">
          </div>
          <div class="flex flex-col items-center">
            <p class="text-center">
              {{ mappedSongs[0].songName }}
            </p>
            <p class="text-center">
              {{ mappedSongs[0].artist }}
            </p>
          </div>
        </div>
        <div class="flex flex-col gap-6 items-center text-center">
          <div class="flex flex-col gap-1">
            <p class="text-xl">¡HA SIDO LA GANADORA!</p>
            <p class="text-lg">¿Preparado para disfrutarla?</p>
          </div>
        </div>
      </div>
      <button v-if="!noRanking" @click="goToRanking" class="flex justify-center bg-tp-yellow p-3 rounded-xl">
        <p class="font-semibold text-md text-tp-black">
          Volver al Ranking
        </p>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import trophy1 from '@/assets/images/trophy-1.png';
import trophy2 from '@/assets/images/trophy-2.png';
import trophy3 from '@/assets/images/trophy-3.png';
import trophy4 from '@/assets/images/trophy-4.png';
import vinyl from '@/assets/images/vinyl.png';
// import VotingSong from '@/components/GeneralVoting/VotingSong.vue';
import NavBar from '@/components/Common/NavBar.vue';
import arrowBack from '@/assets/images/arrow-back.png';
import confetti from 'canvas-confetti';

export default {
  name: 'GeneralVoting',
  components: {
    // VotingSong,
    NavBar
  },
  props: {
    loungeDetails: Object
  },
  setup(props, {emit}) {
    const details = ref(null);
    const detailsLoaded = ref(false);
    const mappedSongs = ref([]);
    const timerFinished = ref(true);
    const isRanking = ref(null);
    const songsData = ref(null);
    const firstSong = ref (null);
    const noRanking = ref(false);

    onMounted(async () => {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });

      details.value =  props.loungeDetails;
      isRanking.value= props.loungeDetails.public.status === 'FINISHED_RANKING';
      if (details.value) {        
        if (isRanking.value){
          songsData.value = Object.values(details.value.public.ranking.songs).map(song => song.songInfo);
          firstSong.value =  details.value.public.lastRankingWinner;
        }
        else{
          noRanking.value = props.loungeDetails.public.ranking === null;
          mappedSongs.value  = Object.values(details.value.public.votingData.songs);
        }

        if (firstSong.value) {
          mappedSongs.value.unshift(firstSong.value); // agregar el firstSong al principio
        }
        detailsLoaded.value = true;
      }
    });

    const goToRanking = () => {
      emit('goToRanking')
    };

    return {
      trophy1,
      trophy2,
      trophy3,
      trophy4,
      vinyl,
      mappedSongs,
      timerFinished,
      isRanking,
      detailsLoaded,
      goToRanking,
      arrowBack,
      noRanking
    };
  }
};
</script>
